import {
  NormalizedCacheObject,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  ApolloClient,
  split
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { IApolloHeaders } from 'src/interfaces';
import getConfig from 'next/config';
import { NextRouter } from 'next/router';
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from '@apollo/client/utilities';
import { Session } from 'next-auth';

export interface IClientContext {
  session: Session;
  router?: NextRouter;
}

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();

const getHeaders = (session: Session): IApolloHeaders => {
  const headers = {} as IApolloHeaders;

  if (session) {
    headers.Authorization = `Bearer ${session.user.accessToken}`;
  }

  return headers;
};

export const getApolloClient = (ctx: IClientContext): ApolloClient<NormalizedCacheObject> => {
  const authMiddleware = new ApolloLink((operation, forward) => {
    const headers = getHeaders(ctx.session);
    operation.setContext({ headers });

    return forward(operation);
  });

  const errorMiddleware = onError(({ graphQLErrors = [], operation }) => {
    if (0 < graphQLErrors.length) {
      let isAuthError = false;

      graphQLErrors.some(({ extensions }) => {
        isAuthError = 'invalid-jwt' === extensions?.code;

        return isAuthError;
      });

      if (isAuthError && typeof window !== 'undefined') {
        if (!ctx.router) {
          console.error('Try to redirect without router.');

          return;
        }

        ctx.router.push('/login');
      }
    }
  });

  const host = serverRuntimeConfig?.apiHost ?? publicRuntimeConfig.apiHost ?? window.location.host;
  const httpProtocol = serverRuntimeConfig?.useTLS ?? publicRuntimeConfig.useTLS ? 'https' : 'http';
  const httpLink = new HttpLink({ uri: `${httpProtocol}://${host}/v1/graphql` });
  const wsProtocol = serverRuntimeConfig?.useTLS ?? publicRuntimeConfig.useTLS ? 'wss' : 'ws';
  const wsLink = new WebSocketLink({
    uri: `${wsProtocol}://${host}/v1/graphql`,
    options: {
      reconnect: true,
      reconnectionAttempts: 5,
      connectionParams: {
        headers: {
          Authorization: `Bearer ${ctx?.session?.user.accessToken}`
        }
      }
    }
  });

  const isSSR = typeof window === 'undefined';
  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription' &&
        !isSSR
      );
    },
    wsLink,
    httpLink
  );

  const cache = new InMemoryCache();

  return new ApolloClient({
    link: authMiddleware.concat(errorMiddleware).concat(link),
    cache: cache,
    ssrMode: isSSR,
  });
};
